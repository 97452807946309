import React from "react";
import Image from "../image/image.js";
import { Helmet } from "react-helmet";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import "./style.scss";
import "swiper/swiper.scss";

const Header = ({ title, description, categoryIcon, logo, metaDescription}) => {
  const BANNERS = [
    {
      title: "Incrementa le prenotazioni dirette, otterrai maggiori profitti",
      filename: "aumentaituoiguadagni.png",
      alt: "Valigia",
      to: "/",
    },    
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | Book@Me - Booking Engine &amp; Channel Manager</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content="Book@Me - Booking Engine &amp; Channel Manager" />
        <meta property="og:image" content="https://static.acmeproduzioni.it/bookatme/logo.svg" />
        <meta property="og:description" content="Book@Me è un booking engine con channel manager integrato in cloud che ti consente di ricevere prenotazioni dirette senza commissioni."/>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {title === "Home" ? (
        <div className="header">
          {BANNERS.map((i, index) => (
            <div className="container" key={index}>
              <div className="columns ">
                <div className="column is-fullhd">
                  <div className="title">
                    <h1>{i.title}</h1>
                  </div>
                  <Image
                    className="imgSlider"
                    filename={i.filename}
                    alt={i.alt}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="header">
          <div className="section" />
          <div className="container">
            <div className="columns">
              <div className="column ">
                <div className="title">
                  <h1>{title}</h1>
                </div>
                {description && (
                  <div className="columns mt-6">
                    <div className="column is-half is-offset-one-quarter ">
                      <p className="center">
                        <br />
                        {description}
                      </p>
                    </div>
                  </div>
                )}
                {!logo && <div className="section" />}
                {categoryIcon && (
                  <Controller>
                    <Scene offset={100} duration="200%" triggerHook="onEnter">
                      <Timeline wrapper={<div className="parallax" />}>
                        <Tween
                          position="0"
                          from={{
                            top: "120%",
                            scale: 0.5,
                          }}
                          to={{
                            top: "-100%",
                            scale: 1,
                          }}
                        >
                          <img src={categoryIcon.icon} alt={categoryIcon.alt} />
                          <div id="ball1"></div>
                          <div id="ball2"></div>
                          <div id="ball3"></div>
                        </Tween>
                      </Timeline>
                    </Scene>
                    <div className="section" />
                  </Controller>
                )}
              </div>
            </div>
          </div>
          {logo && (
            <>
              <div className="columns">
                <div className="column is-4 is-offset-4">
                  <div className="section" />
                  <Image filename={logo} alt={title} />
                  <div className="section" />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
